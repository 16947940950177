export interface BaseUserPreferences {
	/**
	 * App language.
	 */
	language: string;

	/**
	 * App theme.
	 */
	theme: 'dark' | 'light' | 'system';

	/*
	 * Enable sending diagnostics and usage information
	 */
	diagnostics: boolean;

	/*
	 * Enable sending crash log file
	 */
	crashReport: boolean;

	/**
	 * Selected interfaceId for sysapi
	 */
	networkInterfaceId: string;
}

export enum AnalyticsCrashReportOptIn {
	IN = 'IN',
	OUT = 'OUT'
}
