import { proxyCustomElement, HTMLElement, h, Host } from '@stencil/core/internal/client';
const prismGraduatedContainerCss = ":host{display:flex;--_padding:var(--prism-graduated-container-padding, 0px)}:host([orientation=horizontal]){flex-direction:column;--prism-slider-padding-inline:var(--_padding)}:host([orientation=vertical]){--prism-slider-padding-block:var(--_padding)}.pwc-scale-wrapper.has-slider.vertical{padding-block:var(--_padding)}.pwc-scale-wrapper.has-slider.horizontal{padding-inline:var(--_padding)}";
const PrismGraduatedContainerStyle0 = prismGraduatedContainerCss;
const PrismGraduatedContainer$1 = /*@__PURE__*/proxyCustomElement(class PrismGraduatedContainer extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.__attachShadow();
    this.ticks = [];
    this.orientation = 'vertical';
    this.hasSlider = false;
  }
  render() {
    return h(Host, {
      key: '96cf3c0b9a6db4483212998b495f84893e873965'
    }, h("div", {
      key: '8269e9cf27541abf759ed48771e17d469ee81754',
      class: `pwc-scale-wrapper ${this.orientation}${this.hasSlider ? ' has-slider' : ''}`
    }, h("slot", {
      key: 'dea7df46bd4d8ded4d24337c1a1c7563514e9b28',
      name: "scale-before",
      onSlotchange: e => this.scaleSlotChange(e, true)
    })), h("slot", {
      key: '138c612aace3b2dec4b585d250c26c2853205172',
      onSlotchange: e => this.mainSlotChange(e)
    }), h("div", {
      key: 'a3fcd837efb9de346f124f4d293c8cfd50719b41',
      class: `pwc-scale-wrapper after ${this.orientation}${this.hasSlider ? ' has-slider' : ''}`
    }, h("slot", {
      key: 'b35c1f160af7b19bcec24aa8a3409988f4c95250',
      name: "scale-after",
      onSlotchange: e => this.scaleSlotChange(e, false)
    })));
  }
  scaleSlotChange(event, before) {
    if (event.target.assignedElements()[0].tagName === 'PRISM-SCALE') {
      const scale = event.target.assignedElements()[0];
      scale.orientation = this.orientation;
      this.ticks = scale.ticks;
      if (before) {
        scale.position = this.orientation === 'vertical' ? 'left' : 'top';
      } else {
        scale.position = this.orientation === 'vertical' ? 'right' : 'bottom';
      }
    }
  }
  mainSlotChange(event) {
    const slottedElement = event.target.assignedElements()[0];
    if (slottedElement && slottedElement.tagName === 'PRISM-SLIDER') {
      this.sliderElement = slottedElement;
      this.hasSlider = true;
      if (this.ticks) {
        this.sliderElement.ticks = this.ticks;
      }
    }
  }
  static get style() {
    return PrismGraduatedContainerStyle0;
  }
}, [1, "prism-graduated-container", {
  "orientation": [513],
  "hasSlider": [32]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["prism-graduated-container"];
  components.forEach(tagName => {
    switch (tagName) {
      case "prism-graduated-container":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, PrismGraduatedContainer$1);
        }
        break;
    }
  });
}
const PrismGraduatedContainer = PrismGraduatedContainer$1;
const defineCustomElement = defineCustomElement$1;
export { PrismGraduatedContainer, defineCustomElement };

